<template>
  <div>
    <Card>
      <h3>{{label}}</h3>
      <v-textarea
        v-model="text"
        outlined
        name="input"
        label="Gib deinen Text ein..."
        rows="20"
        row-heigt="10"
        :disabled="locked"
      ></v-textarea>
      <v-btn v-if="!locked"
        class="ma-2"
        color="info"
        :loading='loading'
        @click="saveText()"
        >Speichern
      </v-btn>
      <v-btn v-if="locked"
        class="ma-2"
        dark
        :loading='loading'
        @click="locked = false"
        >Ändern
      </v-btn>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
import { mapState } from 'vuex'

export default {
  name: 'Textarea',
  props: {
    label: {
      type: String,
      default: 'Gib deinen Text ein:'
    }
  },
  components: {
    Card
  },
  data () {
    return {
      locked: false,
      text: null
    }
  },
  computed: mapState(['loading']),
  methods: {
    saveText: async function () {
      await this.$store.dispatch('assignCache', { text: this.text })
      this.locked = true
    }
  },
  mounted () {
    const cache = this.$store.state.cache
    if ('text' in cache) {
      this.text = cache.text
      this.locked = true
    } else {
      this.locked = false
    }
  },
  updated () {
    const cache = this.$store.state.cache
    if ('text' in cache === false) {
      this.locked = false
    }
  }
}
</script>
