<template>
  <div class="Replicator">
    <SelectBrick></SelectBrick>
    <Textarea />
    <CheckOut :func="upload" :status="response.status" :ready="ready"/>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import SelectBrick from '@/components/smap/SelectBrick.vue'
import Textarea from '@/components/Textarea.vue'
import CheckOut from '@/components/CheckOut.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Replicator',
  components: {
    SelectBrick,
    Textarea,
    CheckOut
  },
  data () {
    return {
      response: {
        status: null,
        body: null
      },
      keys: [
        'brickId',
        'text'
      ]
    }
  },
  computed: {
    ...mapState(['loading', 'api', 'cache']),
    ...mapGetters({
      allSmaps: 'getMinSmaps',
      token: 'getToken'
    }),
    ...mapState('smap', ['id']),
    ready () {
      const cache = this.cache
      for (var i = 0; i !== this.keys.length; ++i) {
        if (!(this.keys[i] in cache) || this.id === null) {
          return false
        }
      }
      return true
    }
  },
  methods: {
    async request () {
      const url = this.api + '/repliziser'
      const body = {
        token: this.token,
        smapId: this.id,
        brickId: this.cache.brickId,
        text: this.cache.text
      }
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      if (response.ok) {
        return {
          status: true,
          body: 'ok'
        }
      } else {
        return {
          status: false,
          body: response.body
        }
      }
    },
    async upload () {
      this.response = await this.request()
    }
  }
}
</script>
