<template>
  <div>
    <br>
    <v-expand-transition>
      <v-card
        width="85%"
        max-width="600px"
        min-height="100px"
        elevation="15"
        outlined
        class="mx-auto"
        >
        <div class="in-card">
          <slot />
        </div>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'Card'
}
</script>

<style scoped>
.in-card{
  margin: 25px;
  text-align: center;
}
</style>
