<template>
  <div>
    <Card>
      <h3>{{label}}</h3><br>
      <v-autocomplete
            v-model="id"
            :items="ids"
            outlined
            label="Wähle einen Baustein aus..."
            :disabled="(!ready || loading)||!smapLoaded"
            :loading="!ready && logged && smapLoaded"
            clearable
            :success="stored && id != null"
          ></v-autocomplete>
        <v-expand-transition>
        <v-alert v-if="id != null && !stored"
        text
        color="success"
        >
          <h3>ID: {{brick.id}}</h3>
          <h4>Label: {{brick.label}}</h4>
          <br>
          <v-btn
          color="success"
          outlined
          @click="saveBrick()"
          >
            Speichern
          </v-btn>
        </v-alert>
      </v-expand-transition>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SelectBrick',
  props: {
    label: {
      type: String,
      default: 'Wähle einen Baustein aus...'
    },
    cache_as: {
      type: String,
      default: 'brickId'
    },
    type: {
      type: String,
      default: null
    }
  },
  components: {
    Card
  },
  data () {
    return {
      id: null,
      storedBrickId: null
    }
  },
  computed: {
    ...mapState(
      ['logged', 'loading']
    ),
    ...mapGetters({
      storedToken: 'getToken'
    }),
    ready () {
      if (this.allBricks.length === 0) {
        return false
      } else {
        return true
      }
    },
    smapLoaded () {
      return this.$store.state.smap.id != null
    },
    allBricks () {
      const allBricks = this.$store.state.smap.minBricks
      console.log(allBricks)
      if (this.type === null) {
        return allBricks
      }
      return allBricks.filter(e => e.type === this.type)
    },
    labels () {
      return this.allBricks.map(e => e.label)
    },
    ids () {
      return this.allBricks.map(e => e.id)
    },
    brick () {
      if (this.id === null) {
        return [{
          id: null,
          label: null
        }]
      } else {
        return this.allBricks.find(e => e.id === this.id)
      }
    },
    stored () {
      return this.id === this.storedBrickId
    }
  },
  methods: {
    saveBrick () {
      this.$store.dispatch('assignCache', { [this.cache_as]: this.brick.id })
      this.storedBrickId = this.brick.id
    }
  },
  watch: {
    allBricks () {
      this.id = null
    }
  },
  mounted () {
    if ('brickId' in this.$store.state.cache) {
      this.storedBrickId = this.$store.state.cache.brickId
      this.id = this.storedBrickId
    }
    if (!this.ids.includes(this.id)) {
      this.id = null
    }
  }
}
</script>
